<!--四大文件管理-->
<template>
  <page-container title="承接查验报告" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">

            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>

            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开-->
<!--                <a-icon :type="showAdvanced?'up':'down'"></a-icon>-->
<!--              </a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">承接查验报告</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <span slot="undertakeInspection_path" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.undertakeInspection_path!==''&&record.undertakeInspection_path" class="text" @click.stop="downloadFileClick(record.undertakeInspection_path)">
                {{ record.undertakeInspection_path.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="legacyissues_path" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.legacyissues_path!==''&&record.legacyissues_path" class="text" @click.stop="downloadFileClick(record.legacyissues_path)">
                {{ record.legacyissues_path.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>
          <span slot="protocol_path" slot-scope="value, record" class="service_standard_annex-view">
              <a-tag v-if="record.protocol_path!==''&&record.protocol_path" class="text" @click.stop="downloadFileClick(record.protocol_path)">
                {{ record.protocol_path.split("/").pop()}}
              </a-tag>
            <a-tag v-else class="text red">
                无附件
              </a-tag>
          </span>

          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="承接查验报告" prop="undertakeInspection_path">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadAnnexData" action="/upload" :file-list="undertakeInspectionList" @change="undertakeInspectionUploaded"
                    accept=".doc,.docx,.pdf,.xls,.xlsx" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="承接查验协议" prop="protocol_path">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadAnnexData" action="/upload" :file-list="protocolList" @change="protocolUploaded"
                    accept=".doc,.docx,.pdf,.xls,.xlsx" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="遗留问题整改跟进报告" prop="legacyissues_path">
          <a-upload name="fileList" :headers="uploadHeaders" :data="uploadAnnexData" action="/upload" :file-list="legacyissuesList" @change="legacyissuesUploaded"
                    accept=".doc,.docx,.pdf,.xls,.xlsx" :beforeUpload="beforeUpload">
            <a-button :disabled="modalType==='detail'"> <a-icon type="upload" /> 点击上传文件 </a-button>
          </a-upload>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceUndertakeInspection,
  getUndertakeInspectionListByCondition,
  deleteOneUndertakeInspection,
  editUndertakeInspection
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {mapGetters} from "vuex";
import {userdep} from "V/dataAnalysis/performance/minins/userdep";

export default {
  name: 'undertakeInspection',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,

      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '承接查验报告',
          path: ''
        }
      ],
      queryParams: {
        userdepid:'',
        selectDeptList:[]
      },
      //服务内容 收费类型	收费标准	收费形式	服务标准
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '承接查验报告',
          dataIndex: 'undertakeInspection_path',
          key: 'undertakeInspection_path',
          scopedSlots: { customRender: 'undertakeInspection_path' }
        },
        {
          title: '承接查验协议',
          dataIndex: 'protocol_path',
          key: 'protocol_path',
          scopedSlots: { customRender: 'protocol_path' }
        },
        {
          title: '遗留问题整改跟进报告',
          dataIndex: 'legacyissues_path',
          key: 'legacyissues_path',
          scopedSlots: { customRender: 'legacyissues_path' }
        },

        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      undertakeInspectionList:[],
      legacyissuesList:[],
      protocolList:[],
      requirementList:[],

      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        undertakeInspection_path: '',
        legacyissues_path: '',
        protocol_path: '',

        userdepid:"",
        selectDeptList:[],
      },
      modalRules: {
        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedUndertakeInspection:null
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadAnnexData']),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      console.log(params)
      getUndertakeInspectionListByCondition(params).then(res => {
        this.tableLoading = false;
        console.log(res.item)
        if (res && res.returncode === '0') {
          this.tableData = res.item?res.item:[]
          this.pagination.total = res.count;
        }
        console.log(this.tableData)
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;

      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0

        this.modalForm.undertakeInspection_path=''
        this.modalForm.legacyissues_path=''
        this.modalForm.protocol_path=''
        this.legacyissuesList=[]
        this.undertakeInspectionList=[]
        this.protocolList=[]

        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true;
        this.modalForm.id=record.id


        this.modalForm.protocol_path=record.contract
        this.modalForm.legacyissues_path=record.convention
        this.modalForm.undertakeInspection_path=record.manual

        const undertakeInspection_path_file_name = record.undertakeInspection_path!==''&&record.undertakeInspection_path?record.undertakeInspection_path.split("/").pop(): '';
        this.undertakeInspectionList = undertakeInspection_path_file_name!==''?[{
          uid: 'filenameuid',
          name: undertakeInspection_path_file_name,
          status: 'done',
          url: record.undertakeInspection_path,
        }]:[]

        const legacyissues_path_file_name = record.legacyissues_path!==''&&record.legacyissues_path?record.legacyissues_path.split("/").pop():'';
        this.legacyissuesList = legacyissues_path_file_name!==''?[{
          uid: 'filenameuid',
          name: legacyissues_path_file_name,
          status: 'done',
          url: record.legacyissues_path,
        }]:[]

        const protocol_path_file_name = record.protocol_path!==''&&record.protocol_path?record.protocol_path.split("/").pop():'';
        this.protocolList = protocol_path_file_name!==''?[{
          uid: 'filenameuid',
          name: protocol_path_file_name,
          status: 'done',
          url: record.protocol_path,
        }]:[]
        this.modalForm.userdepid = record.userdep_id
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(undertakeInspection_id) {
      if (undertakeInspection_id) {
        let params = {
          undertakeInspection_id
        };
        deleteOneUndertakeInspection(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑违约单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {

        undertakeInspection_path:this.modalForm.undertakeInspection_path,
        legacyissues_path:this.modalForm.legacyissues_path,
        protocol_path:this.modalForm.protocol_path,

        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformanceUndertakeInspection(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        editUndertakeInspection(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedUndertakeInspection = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedUndertakeInspection)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedUndertakeInspection)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedUndertakeInspection)
      } else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedUndertakeInspection)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    undertakeInspectionUploaded(info) {
      console.log(info)
      let fileList = [...info.fileList];
      console.log(fileList)
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.undertakeInspection_path = file.response.urlPath;
        }
        return file;
      });
      this.undertakeInspectionList = fileList;
    },
    legacyissuesUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.legacyissues_path = file.response.urlPath;
        }
        return file;
      });
      this.legacyissuesList = fileList;
    },
    protocolUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.modalForm.protocol_path = file.response.urlPath;
        }
        return file;
      });
      this.protocolList = fileList;
    },
    downloadFileClick(path){
      if(path!==''){
        const pageUrl = window.location.origin;
        const name = path.split("/").pop();
        const download_path=pageUrl+"/"+path;
        let a = document.createElement('a');
        a.href = download_path;
        a.download = name;
        a.click();
      }else{
        this.$message.error("文件为空！");
      }
    },
    beforeUpload(file){
      return new Promise((resolve, reject) => {
        const isJpgOrPng =
            file.type === "application/pdf" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/msword"||
            file.type === "application/vnd.ms-excel"||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isJpgOrPng) {
          this.$message.error("上传文件格式只能是doc/docx/pdf/xls/xlsx");
          return reject(false);
        }
        const isLimit = file.size / 1024 / 1024 < 5;
        if (!isLimit) {
          this.$message.error("上传文件大小不能超过 5MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.service_standard_annex-view {
  display: flex;
  align-items: center;
  .text {
    display: inline-block;
    margin-left: 7px;
    //border: 1px solid black;
    color: #57c921;
    &.red {
      color: #e70c0c;
    }
  }
}
</style>